<template>
  <div>
    <div class='basicInformation accountInformation mt-6 ' >
      <div class='basicInformation_text'>应用接入账户 & 密钥</div>
      <div class="accountInformation_bottom">
        <div class='flex mt-10'>
          <div class='name accountBalance'>appId：</div>
          <div class='content'>
            <span class='text'  >{{appId}}</span>
            <i class="el-icon-document-copy" @click="copy(appId)" ></i>
          </div>
        </div>
        <div class='flex mt-10'>
          <div class='name accountBalance'>appSecret：</div>
          <div class='content'>
            <span class='text' >{{appSecret}}</span>
            <i class="el-icon-document-copy" @click="copy(appSecret)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {appConfig} from "@/api/login";
import Clipboard from 'clipboard'
export default {
  name: "index",
  data: () => ({
    appId:'',
    appSecret:'',
  }),
  computed: {},
  watch: {},
  mounted() {
    this.appConfig()
  },
  methods: {

    copy(val){
      let clipboard = new Clipboard('.el-icon-document-copy', {
        text: function() {
          return val;
        }
      });

      clipboard.on('success', (e)=> {
        this.$message.success('复制成功')
        // 释放内存
        clipboard.destroy()
      });

      clipboard.on('error', (e)=> {
        this.$message.error('该浏览器或手机权限不支持复制功能')
        // 释放内存
        clipboard.destroy()
      });
    },

    async appConfig(){
      try {
        const  { data } = await appConfig()
        const { appId, appSecret} = data
        this.appId = appId
        this.appSecret = appSecret
      }catch (e) {
        this.$message.error(e)
      }
    },

  }
}
</script>

<style scoped lang='less'>
.el-icon-document-copy {
  margin-left: 10px;
  cursor: pointer;
}
.flex {
   display: flex;
}
.basicInformation {
  //border: 1px solid #eee;
  //height: 200px;
  .basicInformation_text {
    font-weight: 600;
    font-size: 16px;
    background: #f2f2f2;
    padding: 10px;
    line-height: 30px;
  }
  .info {
    padding: 20px 10px 20px 10px;
    border: 1px solid #eee;
    .mt-4 {
      margin-top: 10px;
    }
    .name {
      font-size: 14px;
      width: 200px;
      display: flex;
      justify-content: flex-end;
      margin-right:25px ;
      color: #909399;
    }
    .content {
      font-size: 14px;
      color: black;
    }
  }
}
.accountInformation {
  //height: 190px;
  .name {
    width: 200px;
    display: flex;
    justify-content: flex-end;
    margin-right:20px ;
    color: #909399;
    font-size: 14px;
  }
  .content {
    font-size: 16px;
    color: black;
    .text {
      //color: #f5222d;
      margin-right: 5px;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
.accountInformation_bottom {
  border: 1px solid #eee;
  padding: 15px;

}
.accountBalance {
  line-height:25px ;
}
</style>